import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import * as turf from '@turf/turf';

import 'jquery-ui/ui/widgets/autocomplete'; // Import specific widget

// Initialize Sentry using the environment variable MIX_SENTRY_DSN_JS
if (process.env.MIX_SENTRY_DSN_JS) {
    Sentry.init({
        dsn: process.env.MIX_SENTRY_DSN_JS,
        integrations: [
            new Integrations.BrowserTracing()
        ],
        tracesSampleRate: 1.0, // Adjust this value in production for better performance
    });
}

require('./bootstrap');
window.$ = window.jQuery = require('jquery');

$(function () {
    if (typeof searchUrl !== 'undefined') {
        if (searchUrl.includes('mywork') || searchUrl.includes('mobilization')) {
            checkSearchResultsHeader();
        }

        if (searchUrl.includes('mywork')) {
            updateMyWorkTableColours();
        }

        var typingTimer;
        var doneTypingInterval = 1000; // 1 second delay between updates

        $("form").on('submit', function () {
            showLoader();
        });

        // Global Search Functions
        $('.search').on('keyup', function () {
            var searchArray = [];

            $('.searchOn').each(function () {
                var searchOnValue = $(this).val().trim();
                var searchValue = $(this).siblings('.search').val().trim();
                var showAll = $('.active-btn').text().trim(); // Find active button text

                if (searchOnValue !== '' && searchValue !== '') {
                    searchArray.push({
                        searchOn: searchOnValue,
                        search: searchValue,
                        showAll: showAll
                    });
                }
            });

            clearTimeout(typingTimer);
            typingTimer = setTimeout(function () {
                doneTyping(searchArray);
            }, doneTypingInterval);
        });

        function updateSearchResults(searchArray) {
            showLoader();
            var searchTest = searchArray.length > 0 ? true : false;

            if (!searchTest) {
                location.reload();
            }

            if ($('#sortTable').length && $.fn.DataTable.isDataTable('#sortTable')) {
                $('#sortTable').DataTable().destroy();
            }

            let fromDate = $('#fromDate').val();
            let toDate = $('#toDate').val();
            let data = {
                searchArray: searchArray
            };

            if (fromDate) {
                data.fromDate = fromDate;
            }
            if (toDate) {
                data.toDate = toDate;
            }

            $.ajax({
                url: searchUrl,
                type: 'GET',
                data: data,
                dataType: 'json',
                success: function (response) {
                    $('#tableBody').html(response.html);
                    $('#paginationLinks').html(response.pagination);
                    $('#showAllFilter').html(response.showAll);

                    if (searchUrl.includes('mywork')) {
                        updateMyWorkTableColours();
                    }
                    if (searchUrl.includes('mywork') || searchUrl.includes('mobilization')) {
                        checkSearchResultsHeader();
                    }
                    if (searchUrl.includes('document')) {
                        updateDocumentBackgroundColour();
                    }
                    initializeTooltips();

                    if (typeof checkRadioButton === 'function') {
                        checkRadioButton();
                    }

                    hideLoader();

                    if ($('#sortTable').length) {
                        $('#sortTable').DataTable({
                            paging: false,
                            bInfo: false,
                            bFilter: false
                        });
                    }
                },
                error: function (xhr, status, error) {
                    console.error('Error fetching search results:', error);
                    hideLoader();
                }
            });
        }

        function doneTyping(searchArray) {
            updateSearchResults(searchArray);
        }
    }
});

$(window).on('popstate', function () {
    hideLoader();
});

$('.unauthorizedPopup').on('click', function () {
    $('#authorizedModal').modal('show');
});

$('.underworksPopup').on('click', function () {
    $('#underworksModal').modal('show');
});

function showLoader() {
    $('#loader-wrapper').show();
}

function hideLoader() {
    $('#loader-wrapper').hide();
}

function setRequiredAstrixForDocuments() {
    // Iterate over each table cell with the "mandatory" class
    $('td.mandatory').each(function () {
        const $cell = $(this);

        if ($cell.text() == 1) {
            $cell.closest('tr').find('td.name').append('<span class="red-asterisk">*</span>');
        }
    });
}

function checkRadioElements() {
    // Check if any checkbox with the id 'checkedUser' is checked
    if ($('.taskSelect').is(":checked")) {
        // If any checkbox is checked, enable delete button
        $('.serviceIssue').prop('disabled', false);
    } else {
        // If no checkbox is checked, disable delete button
        $('.serviceIssue').prop('disabled', true);
    }
}

function initializeTooltips() {
    // Initialize Download Icons
    document.querySelectorAll('[id^="download-icon-"]').forEach(function (icon) {
        const index = icon.id.split('-').pop(); // Extract the unique index
        const content = document.querySelector(`#download-content-${index}`);
        if (icon._tippy) {
            icon._tippy.destroy(); // Destroy existing instance to prevent duplicates
        }
        tippy(icon, {
            content: content,
            allowHTML: true,
            interactive: true,
            placement: 'bottom',
            appendTo: document.body,
            trigger: 'click'
        });
    });

    // Initialize View Icons
    document.querySelectorAll('[id^="view-icon-"]').forEach(function (icon) {
        const index = icon.id.split('-').pop(); // Extract the unique index
        const content = document.querySelector(`#view-content-${index}`);
        if (icon._tippy) {
            icon._tippy.destroy(); // Destroy existing instance to prevent duplicates
        }
        tippy(icon, {
            content: content,
            allowHTML: true,
            interactive: true,
            placement: 'bottom',
            appendTo: document.body,
            trigger: 'click'
        });
    });
}

function updateDocumentBackgroundColour() {
    $('td.date').each(function () {
        const $cell = $(this);
        const cellDate = extractFormattedDate($cell);
        const $row = $cell.closest('tr');

        // Default to disabling the button
        const $addNewDocumentButton = $row.find('.addNewDocument');
        $addNewDocumentButton.addClass('disabled-element').css({
            'pointer-events': 'none',
            'color': 'grey'
        });

        // Check if the date is within two weeks or is in the past
        const isWithinRangeOrPast = isWithinTwoWeeksOrPast(cellDate);

        // Check if the cell is empty and the corresponding row has a mandatory field with value 1
        const isMandatory = $row.children('td.mandatory').text() == 1;
        const isCellEmpty = $cell.text() === '';

        if (isWithinRangeOrPast || isCellEmpty) {
            // Re-enable the button and set the background color if conditions are met
            if (typeof isReadOnly !== 'undefined' && !isReadOnly) {
                $addNewDocumentButton.removeClass('disabled-element').css({
                    'pointer-events': 'auto',
                    'color': ''
                });
            }
            if (isMandatory) {
                $row.children('td.fileName, td.notes, td.date, td.approved, td.icons, td.documentId').css('backgroundColor', '#FFB4B0');
            }
        }
    });
}


// Function to get and format the date from a table cell with the "date" class
function extractFormattedDate(cell) {
    // Extract the date from the cell and format it as MM/DD/YYYY
    return new Date(cell.text().replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
}

/**
 * Checks if a given date is within two weeks of the current date or is before today.
 * @param {Date} date - The date to check.
 * @return {boolean} - Returns true if the date is within two weeks or is before today.
 */
function isWithinTwoWeeksOrPast(date) {
    const today = new Date(); //
    const twoWeeksFromToday = new Date();
    twoWeeksFromToday.setDate(today.getDate() + 14);

    // Check if the given date is within two weeks from today or is in the past
    return date <= twoWeeksFromToday || date < today;
}


function updateMyWorkTableColours() {
    showSearchHeader();

    // Iterate over each table cell with the class 'dueDate'
    $('td.dueDate').each(function () {
        // Get the current cell wrapped in a jQuery object
        const $cell = $(this);

        // Check if the cell text is 'Completed'
        if ($cell.text().trim() === 'Completed') {
            const $row = $cell.closest('tr'); // Get the parent row

            // Set the background color of the entire row to a light red shade (hex color 'FFB4B0')
            $row.children(
                    'td.partyName, td.binType, td.postCode, td.dueDate, td.action, td.restrictions, td.expand')
                .css('backgroundColor', '#C0C0C0');

            // Find the next row with the child table
            const $childRow = $row.next('tr.collapse');

            // Find the form within the child row
            const $form = $childRow.find('form').first();

            $form.find('.btn-success').hide();
            $form.find('.taskMessage').hide();
            $form.find('.submitButton').hide();

            // Disable all input, select, textarea, and button elements within the form
            $form.find('input, select, textarea, button').prop('disabled', true);
        }
        if ($cell.text().trim() === 'Cancelled') {

            const $row = $cell.closest('tr');

            // Change icon to X to show table row can not be expanded
            $row.children('td.expand').find('.icon').removeClass('bi-arrow-down-short').addClass(
                'bi bi-x');

            // Disable select radio button
            $row.children('td.select').find('.taskSelect').attr("disabled", true);

            // Set the background color of the entire row to a light red shade (hex color 'FFB4B0')
            $row.children(
                    'td.partyName, td.binType, td.postCode, td.dueDate, td.action, td.restrictions, td.expand')
                .css('backgroundColor', 'FFB4B0');
        }
    });
}

function checkSearchResultsHeader() {
    var searchTrue = false;
    $('input[name="search"]').each(function () {
        if ($(this).val().trim().length > 0) {
            searchTrue = true;
            return false; // Break the loop
        }
    });

    if ($('#tableBody tr td').length > 1 || searchTrue) {
        $('#searchContainer').show();
    } else {
        $('#searchContainer').hide();
    }
}

function hideSearchHeader() {
    $('#searchContainer').hide();
}

function showSearchHeader() {
    if ($('tbody tr td').hasClass('nowork')) $('#searchContainer').hide();
    else $('#searchContainer').show();
}

window.initializeTooltips = initializeTooltips;
window.showLoader = showLoader;
window.hideLoader = hideLoader;
window.checkRadioElements = checkRadioElements;
window.updateDocumentBackgroundColour = updateDocumentBackgroundColour;
window.updateMyWorkTableColours = updateMyWorkTableColours;
window.hideSearchHeader = hideSearchHeader;
window.showSearchHeader = showSearchHeader;
window.setRequiredAstrixForDocuments = setRequiredAstrixForDocuments;
